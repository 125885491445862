import React from "react";
import "./styles.css";

const Upcoming = () => (
  <header className="home-header">
     

<h1>
  <span></span>Hi my Lovely Ladies<span>!</span>
</h1>
<h2>
  <span>Looking Ahead: Julebord!!</span></h2>
  <p>As we look to the future, there’s so much to be excited about! I warmly encourage all of you to join our activities. 
  <br />
  <b>Here’s to the Ladies of Stavanger—thank you for making 2024 a year to remember. 
  <br />
  May the months ahead be filled with as much joy, connection, and adventure as the ones we’ve already shared.</b>
</p>

<div className="col-md-4">
  <img className="Los-img" src="../assets/images/iziaLos.png" alt="event1" />
</div>
<br />

    <h1>
      <span></span>Upcoming events <span>!</span>
    </h1>
    <p>
      Join our{" "}
      <a href="https://www.facebook.com/groups/ladiesofstavanger">
        Facebook page
      </a>{" "}
      and find details about: <br />
    </p>
    <div className="col-md-4">
      <img className="Los-img" src="../assets/images/xmass24.png" alt="event1" />
    </div>
    <br />
    <div className="col-md-4">
      <img className="Los-img" src="../assets/images/sundayBrunch.png" alt="event1" />
    </div>
    <br />
    <div className="col-md-4">
      <img className="Los-img" src="../assets/images/ladies.png" alt="event1" />
    </div>
    <br />

    <div className="col-md-4">
      <img className="Los-img" src="../assets/images/ArtTherapyR.jpg" alt="event2" />
    </div>
    <br />

    <h1>
      <span></span>Past events<span></span>
    </h1>
    <h2>
      <span>Thank you for joining</span>
      <span>!</span>
    </h2>
    <div className="col-md-4">
      <img className="Los-img" src="../assets/images/fallPT.png" alt="event1" />
    </div>
    <br />
    <div className="col-md-4">
      <img className="Los-img" src="../assets/images/runz24.png" alt="event1" />
    </div>
    <br />
    <div className="col-md-4">
      <img className="Los-img" src="../assets/images/marina.jpg" alt="event1" />
    </div>
    <br />
    <div className="col-md-4">
      <img className="Los-img" src="../assets/images/summer24.jpg" alt="event1" />
    </div>
    <br />
    <div className="col-md-4">
      <img className="Los-img" src="../assets/images/runningClub.jpg" alt="event1" />
    </div>
    <br />
    <div className="col-md-4">
      <img className="Los-img" src="../assets/images/genAss.jpg" alt="event1" />
    </div>
    <br />
    <div className="col-md-4">
      <img className="Los-img" src="../assets/images/ladies.jpg" alt="event1" />
    </div>
    <br />
    <div className="col-md-4">
      <img className="Los-img" src="../assets/images/jazz.jpg" alt="event1" />
    </div>
    <br />
    <div className="col-md-4">
      <img className="Los-img" src="../assets/images/xmass.jpg" alt="event2" />
    </div>
    <div className="col-md-4">
      <img
        className="Los-img"
        src="../assets/images/ArtTherapy.jpg"
        alt="event3"
      />
    </div>
    <br />
    <div className="col-md-4">
      <img
        className="Los-img"
        src="../assets/images/nextEvent.jpg"
        alt="event4"
      />
    </div>
    <br />
    <div className="col-md-4">
      <img
        className="Los-img"
        src="../assets/images/nextEvent2.jpg"
        alt="event5"
      />
    </div>
    <br />
    <br />
  </header>
);

export default Upcoming;
