import React from "react";
import "./styles.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';

const PhotoCarousel = () => {
  const images = [
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(25).jpg", alt: "25" },
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(2).jpeg", alt: "2" },
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(3).jpeg", alt: "3" },
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(7).jpeg", alt: "7" },
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(8).jpeg", alt: "8" },
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(9).jpeg", alt: "9" },
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(10).jpeg", alt: "10" },
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(11).jpeg", alt: "11" },
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(12).jpeg", alt: "12" },
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(13).jpeg", alt: "13" },
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(14).jpeg", alt: "14" },
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(15).jpeg", alt: "15" },
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(16).jpeg", alt: "16" },
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(17).jpeg", alt: "17" },
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(18).jpeg", alt: "18" },
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(19).jpg", alt: "19" },
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(20).jpg", alt: "20" },
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(22).jpg", alt: "22" },
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(23).jpg", alt: "23" },
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(24).jpg", alt: "24" },
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(1).png", alt: "26" },
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(2).png", alt: "27" },
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(3).png", alt: "28" },
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(4).jpg", alt: "29" },
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(5).jpg", alt: "30" },
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(6).jpg", alt: "31" },
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(7).jpg", alt: "32" },
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(4).jpeg", alt: "4" },
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(5).jpeg", alt: "5" },
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(8).jpg", alt: "33" },
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(9).jpg", alt: "34" },
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(10).jpg", alt: "35" },
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(12).jpg", alt: "36" },
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(13).jpg", alt: "37" },
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(15).jpg", alt: "38" },
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(16).jpg", alt: "39" },
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(17).jpg", alt: "40" },
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(18).jpg", alt: "41" },
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(6).jpeg", alt: "6" },
    { src: "https://profitbasedocs.blob.core.windows.net/other/swiper/swiper%20(1).jpeg", alt: "1" },
  ];

  return (
    <Swiper
      modules={[Navigation, Pagination, Autoplay]}
      spaceBetween={10}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      autoplay={{ delay: 2500 }}
    >
      {images.map((image, index) => (
        <SwiperSlide key={index}>
          <img src={image.src} alt={image.alt} className="carousel-image" />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default PhotoCarousel;
