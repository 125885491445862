import React, { useState } from "react";
import Header from "../../components/Home/Header";
import Membership from "../../components/Home/Membership";
import Swiper from "../../components/Home/Swiper";

const Members = () => {
  return (
    <div>
      {/* Swiper */}
      <Swiper />
      {/* Membership */}
      <Membership />
    </div>
  );
};

export default Members;
