import React from "react";
import "./styles.css";
import { teamData } from "./data";

const MeetTheTeam = () => (
  <div id="team" className="text-center">
    <div className="container">
      <div className="section-title">
        <h2>Meet the Team</h2>
      </div>
      <div className="section-subtitle">
        <h1></h1>
      </div>
      <div id="row" className="team-section">
        {teamData ? (
          teamData.map((d, i) => (
            <div key={`${d.name}-${i}`} className="col-md-3 col-sm-6 team">
              <div className="thumbnail">
                <img src={d.img} alt="..." className="team-img" />
                <div className="caption">
                  <h4>{d.name}</h4>
                  <p>{d.job}</p>
                  <p>{d.about}</p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div></div>
        )}
      </div>
      <div className="col-md-4">
            <img
              className="Los-img"
              src="https://profitbasedocs.blob.core.windows.net/other/adminy.jpg"
              alt="group photo"
            />
          </div>
    </div>
  </div>
);

export default MeetTheTeam;
